import React from 'react';

const Amazon = () => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <svg
    viewBox="11.15 160.565 586.51 483.82"
    enableBackground="new 11.15 160.565 586.51 483.82"
  >
    <g>
      <path
        fill="#050606"
        d="M443.342,337.31c0,19.962,0.717,39.804-0.239,59.766c-0.956,22.711,5.977,42.194,20.32,59.406 c1.673,2.032,3.228,4.064,4.661,6.216c5.141,7.77,4.184,13.148-2.868,19.125c-15.062,12.671-30.003,25.46-45.063,38.131 c-8.965,7.53-14.583,7.411-23.667,0c-12.192-9.921-21.755-22.113-30.241-35.143c-2.032-3.107-3.108-2.988-5.618-0.598 c-15.062,14.702-30.959,28.329-51.159,35.143c-31.198,10.638-62.874,12.312-94.191,1.195 c-30.122-10.758-48.171-32.991-54.865-63.949c-7.411-34.545-3.586-67.416,17.69-96.701c16.137-22.113,38.489-35.142,64.188-43.15 c26.536-8.128,53.908-11.236,81.4-13.985c7.77-0.837,15.42-1.554,23.189-2.271c1.912-0.12,3.586-0.359,3.466-2.869 c-0.238-12.67,1.076-25.58-1.912-38.011c-3.825-15.897-14.225-25.221-30.122-28.688c-11.355-2.51-22.591-1.793-33.588,1.913 c-16.734,5.737-27.134,17.332-31.317,34.544c-2.271,9.443-6.694,12.79-15.898,11.833c-19.603-2.032-39.206-4.064-58.689-6.096 c-8.009-0.837-12.192-6.933-10.519-14.703c10.16-49.605,42.673-77.456,89.529-90.246c35.979-9.801,72.436-10.399,108.534-0.239 c29.046,8.128,53.431,22.472,67.057,50.92c7.77,16.137,9.563,33.588,9.802,51.04C443.461,292.247,443.222,314.838,443.342,337.31 C443.222,337.31,443.342,337.31,443.342,337.31z M350.346,374.604c0-5.977-0.238-11.953,0.12-17.93 c0.239-3.706-1.076-5.021-4.781-4.662c-9.085,0.836-18.288,0.956-27.373,2.151c-15.897,2.032-31.437,5.977-44.346,16.017 c-18.767,14.703-22.711,34.784-19.604,57.375c3.706,26.535,27.373,41.238,52.714,32.393c17.212-5.977,27.73-18.646,35.022-34.545 C349.748,409.268,350.346,391.936,350.346,374.604z"
      />
      <path
        fill="#F6A61F"
        d="M306.119,644.386c-91.202-0.598-173.081-24.025-247.071-73.392c-15.061-10.041-29.405-21.038-43.031-33.11 c-1.195-1.076-2.391-2.151-3.347-3.467c-1.315-1.793-2.271-3.944-0.717-6.096c1.554-2.391,4.184-2.63,6.455-1.554 c5.259,2.51,10.28,5.259,15.419,7.889c47.096,24.862,96.701,42.792,148.697,54.147c47.095,10.28,94.668,15.062,142.959,13.746 c68.97-1.912,135.429-15.778,199.378-41.478c3.108-1.314,6.336-2.629,9.563-3.347c4.543-0.837,9.085-0.239,11.117,4.662 c1.912,4.542-0.598,8.128-3.945,10.997c-2.391,2.031-5.14,3.705-7.889,5.498c-41.238,26.775-86.421,43.987-134.233,54.626 C367.917,640.681,335.883,644.146,306.119,644.386z"
      />
      <path
        fill="#F6A61F"
        d="M536.217,537.884c-13.746-0.12-27.372,1.673-40.999,3.107c-2.63,0.239-5.618,0.956-6.813-1.912 c-1.434-3.228,1.315-5.14,3.586-6.694c16.974-11.235,35.979-15.658,55.941-16.734c13.746-0.836,27.372-0.238,40.76,3.825 c6.096,1.793,9.084,5.379,8.965,11.595c-0.717,33.229-11.834,62.156-35.859,85.704c-0.837,0.837-1.793,1.554-2.749,2.271 c-2.151,1.555-4.781,2.988-7.053,1.076c-2.151-1.793-0.956-4.423,0-6.574c7.053-17.571,14.225-35.022,17.69-53.789 c0.479-2.391,0.598-4.781,0.718-7.053c0.119-7.77-2.63-11.116-10.28-13.028C552.115,537.525,544.106,537.884,536.217,537.884z"
      />
    </g>
  </svg>
);

export default Amazon;
