import React from 'react';

const Watch = () => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <svg viewBox="-110 0 512 512">
    <path d="m251.769531 110.996094v-103.496094c0-4.140625-3.359375-7.5-7.5-7.5h-195.996093c-4.140626 0-7.5 3.359375-7.5 7.5v80.050781c0 4.140625 3.359374 7.5 7.5 7.5 4.140624 0 7.5-3.359375 7.5-7.5v-72.550781h180.996093v94.730469h-184.617187c-28.757813 0-52.152344 23.394531-52.152344 52.152343v188.234376c0 28.757812 23.394531 52.152343 52.152344 52.152343h184.617187v94.730469h-180.996093v-72.125c0-4.140625-3.359376-7.5-7.5-7.5-4.140626 0-7.5 3.359375-7.5 7.5v79.625c0 4.140625 3.359374 7.5 7.5 7.5h195.996093c4.140625 0 7.5-3.359375 7.5-7.5v-103.496094c23.300781-5.207031 40.769531-26.039062 40.769531-50.886718v-188.234376c0-24.847656-17.46875-45.679687-40.769531-50.886718zm25.769531 239.121094c0 20.484374-16.664062 37.152343-37.152343 37.152343h-188.234375c-20.484375 0-37.152344-16.667969-37.152344-37.152343v-188.234376c0-20.488281 16.667969-37.152343 37.152344-37.152343h188.234375c20.488281 0 37.152343 16.664062 37.152343 37.152343zm0 0" />
    <path d="m231.480469 146.074219h-20.117188c-4.144531 0-7.5 3.359375-7.5 7.5 0 4.144531 3.355469 7.5 7.5 7.5h20.117188c5.355469 0 9.714843 4.359375 9.714843 9.714843v170.421876c0 5.355468-4.359374 9.710937-9.714843 9.710937h-170.421875c-5.355469 0-9.710938-4.355469-9.710938-9.710937v-170.421876c0-5.355468 4.355469-9.714843 9.710938-9.714843h120.625c4.140625 0 7.5-3.355469 7.5-7.5 0-4.140625-3.359375-7.5-7.5-7.5h-120.625c-13.625 0-24.710938 11.085937-24.710938 24.714843v170.421876c0 13.625 11.085938 24.710937 24.710938 24.710937h170.421875c13.625 0 24.710937-11.085937 24.710937-24.710937v-170.421876c.003906-13.628906-11.082031-24.714843-24.710937-24.714843zm0 0" />
    <path d="m112.632812 219.183594c-2.925781-2.929688-7.675781-2.929688-10.605468 0l-31.515625 31.511718c-1.40625 1.40625-2.195313 3.316407-2.195313 5.304688s.789063 3.894531 2.195313 5.300781l31.515625 31.515625c1.464844 1.464844 3.382812 2.195313 5.304687 2.195313 1.917969 0 3.839844-.730469 5.300781-2.195313 2.929688-2.929687 2.929688-7.679687 0-10.605468l-26.210937-26.210938 26.210937-26.210938c2.929688-2.929687 2.929688-7.679687 0-10.605468zm0 0" />
    <path d="m179.90625 292.816406c1.464844 1.464844 3.382812 2.199219 5.304688 2.199219 1.917968 0 3.835937-.734375 5.300781-2.199219l31.515625-31.511718c2.925781-2.929688 2.925781-7.679688 0-10.609376l-31.515625-31.511718c-2.929688-2.929688-7.675781-2.929688-10.605469 0-2.929688 2.929687-2.929688 7.675781 0 10.605468l26.210938 26.210938-26.210938 26.210938c-2.929688 2.929687-2.929688 7.675781 0 10.605468zm0 0" />
    <path d="m148.589844 197.832031-22.285156 113.445313c-.796876 4.0625 1.851562 8.007812 5.914062 8.804687.488281.097657.976562.140625 1.457031.140625 3.511719 0 6.644531-2.476562 7.347657-6.054687l22.285156-113.445313c.796875-4.066406-1.847656-8.007812-5.914063-8.804687-4.066406-.796875-8.007812 1.847656-8.804687 5.914062zm0 0" />
  </svg>

);

export default Watch;
