import React from 'react';

const Memur = () => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <svg viewBox="-96 0 512 512">
    <path d="m159.808594 456.066406c-8.164063 0-14.808594 6.644532-14.808594 14.808594s6.644531 14.804688 14.808594 14.804688c8.164062 0 14.804687-6.644532 14.804687-14.804688 0-8.164062-6.640625-14.808594-14.804687-14.808594zm0 15.683594c-.484375 0-.878906-.394531-.878906-.875 0-.484375.394531-.878906.878906-.878906.480468 0 .875.394531.875.878906 0 .480469-.394532.875-.875.875zm0 0" />
    <path d="m271.714844 0h-14.847656c-4.140626 0-7.5 3.359375-7.5 7.5s3.359374 7.5 7.5 7.5h14.847656c18.140625 0 32.898437 14.757812 32.898437 32.898438v16.230468h-289.613281v-16.230468c0-18.140626 14.757812-32.898438 32.898438-32.898438h179.207031c4.140625 0 7.5-3.359375 7.5-7.5s-3.359375-7.5-7.5-7.5h-179.207031c-26.410157 0-47.898438 21.488281-47.898438 47.898438v416.203124c0 26.414063 21.488281 47.898438 47.898438 47.898438h223.816406c26.410156 0 47.898437-21.484375 47.898437-47.898438v-416.203124c0-26.410157-21.484375-47.898438-47.898437-47.898438zm0 497h-223.816406c-18.140626 0-32.898438-14.757812-32.898438-32.898438v-384.972656h289.613281v351.070313h-266.972656c-4.144531 0-7.5 3.359375-7.5 7.5s3.355469 7.5 7.5 7.5h266.972656v18.902343c0 18.140626-14.757812 32.898438-32.898437 32.898438zm0 0" />
    <path d="m108.128906 289.898438c1.464844 1.464843 3.382813 2.195312 5.304688 2.195312 1.917968 0 3.835937-.730469 5.300781-2.195312 2.929687-2.929688 2.929687-7.679688 0-10.605469l-32.226563-32.230469 32.226563-32.226562c2.929687-2.929688 2.929687-7.679688 0-10.605469-2.925781-2.929688-7.675781-2.929688-10.605469 0l-37.53125 37.53125c-1.40625 1.402343-2.199218 3.3125-2.199218 5.300781s.792968 3.898438 2.199218 5.304688zm0 0" />
    <path d="m200.878906 289.898438c1.464844 1.464843 3.382813 2.195312 5.304688 2.195312 1.917968 0 3.835937-.730469 5.300781-2.195312l37.53125-37.53125c2.929687-2.929688 2.929687-7.679688 0-10.605469l-37.53125-37.53125c-2.929687-2.929688-7.675781-2.929688-10.605469 0-2.929687 2.925781-2.929687 7.675781 0 10.605469l32.230469 32.226562-32.230469 32.230469c-2.929687 2.925781-2.929687 7.675781 0 10.605469zm0 0" />
    <path d="m143.351562 321.976562c.488282.09375.972657.140626 1.453126.140626 3.511718 0 6.648437-2.476563 7.351562-6.054688l26.539062-135.109375c.796876-4.0625-1.851562-8.003906-5.914062-8.804687-4.066406-.792969-8.007812 1.851562-8.804688 5.914062l-26.539062 135.109375c-.800781 4.0625 1.847656 8.007813 5.914062 8.804687zm0 0" />
  </svg>
);

export default Memur;
