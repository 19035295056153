import React from 'react';

const Pacman = () => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <svg viewBox="0 0 512 512">
    <g>
      <g>
        <path d="M220.287,470.561c-53.732-2.356-104.28-24.609-142.33-62.659c-12.145-12.146-22.811-25.713-31.703-40.32 c-5.02-8.246-17.84-0.465-12.811,7.798c9.511,15.628,20.92,30.138,33.91,43.128c40.709,40.709,94.79,64.517,152.279,67.038 c0.112,0.005,0.223,0.007,0.334,0.007c3.99,0,7.31-3.146,7.486-7.171C227.632,474.244,224.424,470.743,220.287,470.561z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M232.029,26.459C169.845,25.834,111.325,49.727,67.35,93.702C2.075,158.979-17.791,256.486,16.738,342.114 c3.602,8.936,17.522,3.349,13.909-5.608C-1.629,256.466,16.94,165.323,77.956,104.307c40.589-40.588,94.4-62.861,151.743-62.861 c0.727,0,1.456,0.003,2.184,0.011c4.165,0.06,7.531-3.284,7.572-7.425C239.495,29.89,236.17,26.5,232.029,26.459z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M392.156,407.901L240.359,256.104l151.797-151.797c2.929-2.93,2.929-7.677,0-10.605 c-34.145-34.144-77.302-56.337-124.805-64.179c-4.097-0.674-7.945,2.092-8.62,6.177c-0.675,4.086,2.091,7.946,6.177,8.62 c41.928,6.923,80.237,25.812,111.25,54.776L224.452,250.802c-2.929,2.93-2.929,7.677,0,10.605L376.16,413.115 c-34.032,31.813-76.346,51.255-122.847,56.359c-4.116,0.452-7.088,4.155-6.636,8.272c0.421,3.839,3.669,6.682,7.445,6.682 c0.273,0,0.55-0.016,0.828-0.046c51.67-5.671,100.397-29.066,137.207-65.875C395.085,415.577,395.085,410.83,392.156,407.901z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M320.384,232.793c-13.542,0-24.559,11.017-24.559,24.559c0,13.542,11.017,24.559,24.559,24.559 c13.542,0,24.559-11.017,24.559-24.559S333.926,232.793,320.384,232.793z M320.383,266.914c-5.272,0-9.561-4.289-9.561-9.561 c0-5.272,4.289-9.561,9.561-9.561c5.272,0,9.561,4.289,9.561,9.561C329.944,262.623,325.655,266.914,320.383,266.914z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M403.912,232.793c-13.542,0-24.559,11.017-24.559,24.559s11.017,24.559,24.559,24.559s24.559-11.017,24.559-24.559 S417.454,232.793,403.912,232.793z M403.912,266.913c-5.271,0-9.561-4.289-9.561-9.561s4.289-9.561,9.561-9.561 c5.272,0,9.561,4.289,9.561,9.561S409.185,266.913,403.912,266.913z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M487.442,232.793c-13.542,0-24.559,11.017-24.559,24.559s11.017,24.559,24.559,24.559S512,270.894,512,257.352 S500.984,232.793,487.442,232.793z M487.442,266.913c-5.271,0-9.561-4.289-9.561-9.561s4.289-9.561,9.561-9.561 c5.272,0,9.561,4.289,9.561,9.561S492.713,266.913,487.442,266.913z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M197.217,119.812c-13.542,0-24.559,11.016-24.559,24.558c0,13.542,11.017,24.559,24.559,24.559 c13.542,0,24.559-11.017,24.559-24.559C221.777,130.829,210.759,119.812,197.217,119.812z M197.217,153.932 c-5.272,0-9.561-4.289-9.561-9.562c0-5.272,4.289-9.561,9.561-9.561s9.561,4.289,9.561,9.561 C206.778,149.642,202.489,153.932,197.217,153.932z" />
      </g>
    </g>
  </svg>
);

export default Pacman;
