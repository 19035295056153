import React from 'react';

const AppNexus = () => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <svg viewBox="0 0 36 36" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Navigation/Header-Dark-iPhone" transform="translate(-20.000000, -18.000000)">
        <g id="logo" transform="translate(20.000000, 18.000000)">
          <path id="logo" fill="#FF8700" d="M35.254,8.8137 C30.403,8.8017 26.442,4.8537 26.441,0.0007 L35.254,0.0007 L35.254,8.8137 Z M35.254,11.8738 L35.254,23.3678 C29.046,23.3098 23.201,20.8538 18.796,16.4528 C14.392,12.0508 11.919,6.2078 11.857,-0.0002 L23.393,-0.0002 C23.394,6.5348 28.719,11.8628 35.254,11.8738 Z M26.441,35.2536 C26.441,31.1656 25.534,27.2846 23.87,23.8256 C27.319,25.4796 31.18,26.4406 35.254,26.4406 L35.254,35.2536 L26.441,35.2536 Z M8.8048,35.2536 L-0.0002,35.2536 L-0.0002,26.4406 C4.8718,26.4526 8.8038,30.3796 8.8048,35.2536 Z M0.0006,11.8738 C6.2086,11.9318 12.0566,14.3928 16.4626,18.7938 C20.8666,23.1958 23.3306,29.0448 23.3926,35.2538 L11.8566,35.2538 C11.8546,28.7198 6.5356,23.3788 0.0006,23.3678 L0.0006,11.8738 Z M8.8048,0.0002 C8.8048,4.0882 9.7278,7.9612 11.3908,11.4202 C7.9418,9.7672 4.0528,8.8132 -0.0002,8.8132 L-0.0002,0.0002 L8.8048,0.0002 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export default AppNexus;
