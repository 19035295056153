import React from 'react';

const Ultimate = () => (
  /* eslint max-len: ["error", { "ignoreStrings": true }] */
  <svg viewBox="0 0 511 511">
    <g>
      <path d="M471.5,56h-432C17.72,56,0,73.72,0,95.5v320C0,437.28,17.72,455,39.5,455h432c21.78,0,39.5-17.72,39.5-39.5v-320 C511,73.72,493.28,56,471.5,56z M39.5,71h432c13.509,0,24.5,10.991,24.5,24.5V120H15V95.5C15,81.991,25.991,71,39.5,71z M471.5,440 h-432C25.991,440,15,429.009,15,415.5V135h481v280.5C496,429.009,485.009,440,471.5,440z" />
      <path d="M39.5,103c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3c-1.39-1.4-3.33-2.2-5.3-2.2 c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3C35.59,102.2,37.53,103,39.5,103z" />
      <path d="M63.5,103c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3c-1.39-1.4-3.33-2.2-5.3-2.2 c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3C59.59,102.2,61.53,103,63.5,103z" />
      <path d="M87.5,103c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3c-1.39-1.4-3.33-2.2-5.3-2.2 c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3C83.59,102.2,85.53,103,87.5,103z" />
      <path d="M289.774,192.353c-3.945-1.255-8.165,0.926-9.421,4.873l-56,176c-1.256,3.947,0.926,8.165,4.873,9.421 c0.756,0.241,1.522,0.355,2.276,0.355c3.179,0,6.13-2.037,7.145-5.228l56-176C295.903,197.827,293.721,193.609,289.774,192.353z" />
      <path d="M324.803,210.197c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.677,0,10.606l66.697,66.697l-66.697,66.697 c-2.929,2.929-2.929,7.677,0,10.606c1.464,1.464,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197l72-72 c2.929-2.929,2.929-7.677,0-10.606L324.803,210.197z" />
      <path d="M204.803,210.197c-2.929-2.929-7.678-2.929-10.606,0l-72,72c-2.929,2.929-2.929,7.677,0,10.606l72,72 c1.464,1.464,3.384,2.197,5.303,2.197s3.839-0.732,5.303-2.197c2.929-2.929,2.929-7.677,0-10.606L138.106,287.5l66.697-66.697 C207.732,217.874,207.732,213.126,204.803,210.197z" />
      <path d="M119.5,103h304c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-304c-4.142,0-7.5,3.358-7.5,7.5S115.358,103,119.5,103z" />
      <path d="M455.5,103h16c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-16c-4.142,0-7.5,3.358-7.5,7.5S451.358,103,455.5,103z" />
    </g>
  </svg>
);

export default Ultimate;
